<template>
  <b-container class="bv-example-row mb-3" fluid>
    <hr v-if="!spinner"/>
    <b-spinner v-if="spinner" variant="primary" class="spinners"/>
    <div v-if="!spinner" class="konaklama-listesi">
      <b-card-actions
          class="cardActions"
          action-refresh
          ref="refreshCard"
          @refresh="refreshStop('refreshCard')"
      >
        <b-row class="mt-2">
          <b-col cols="4">
            <h2 class="myTitle">
              {{
                baslangic
                    ? baslangic + ' ile ' + bitis + ' tarih aralığındaki kayıtlar'
                    : currentDate + ' Tarihli Konaklama Listesi'
              }}
            </h2>
          </b-col>
          <b-col cols="8">
            <b-input-group class="float-right flat-date justify-content-end">
              <b-input-group-prepend>
                <flat-pickr v-model="baslangic" class="form-control"/>
              </b-input-group-prepend>
              <b-button
                  variant="outline-primary"
                  disabled
                  class="disabledColor"
              >
                <feather-icon icon="CalendarIcon"/>
              </b-button>
              <b-input-group-append>
                <flat-pickr v-model="bitis" class="form-control mr-2"/>
              </b-input-group-append>
              <b-button
                  @click="refreshStop('refreshCard')"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="info"
                  class="myButton mr-50"
              >
                <feather-icon icon="RefreshCcwIcon" class="mr-50"/>
                Sıfırla
              </b-button>
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="info"
                  v-print="'#printMe'"
              >
                <feather-icon icon="PrinterIcon" class="mr-50"/>
                Listeyi Yazdır
              </b-button>
            </b-input-group>
          </b-col>
        </b-row>
        <hr/>
        <b-tabs v-if="!filtre" id="printMe">
          <!--  AKTİF KONAKLAMALAR -->
          <b-tab active title="Aktif Konuklar">
            <b-row>
              <b-col sm="3" md="3" class="my-1">
                <b-input-group>
                  <b-input-group
                      size="sm"
                      prepend="Gösterilecek kayıt sayısı"
                      class="unPrint"
                  >
                    <b-form-select
                        @change="perPageChange($event)"
                        id="per-page-select"
                        v-model="perPage"
                        :options="pageOptions"
                        size="sm"
                    >
                    </b-form-select>
                  </b-input-group>
                </b-input-group>
              </b-col>
              <b-col lg="3" class="my-1 d-flex justify-content-end">
                <b-input-group size="sm" class="searchGroup">
                  <b-input-group-prepend>
                    <b-button
                        variant="outline-primary"
                        size="sm"
                        disabled
                        class="araButton"
                    >
                      Ara
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                  ></b-form-input>
                </b-input-group>
              </b-col>
            </b-row>
            <b-table
                hover
                striped
                empty-text="Veri Bulunamadı"
                :items="salaryListItems"
                :fields="fields"
                :per-page="perPage"
                :filter-included-fields="filterOn"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                stacked="md"
                show-empty
                small
                @filtered="onFiltered"
            >
              <template #cell(odaKodu)="data">
                {{ data.item.odaKodu }}
              </template>
              <template #cell(rezervasyonNo)="data">
                <a>{{
                    data.item.rezervasyonNo
                  }}</a>
              </template>
              <template #cell(adi)="data">
                {{ data.item.adi }} {{ data.item.soyAdi }}
              </template>
              <template #cell(tarife)="data">
                {{ tarifeGet(data.item.tarife) }}
              </template>
              <template #cell(fiyat)="data">
                {{ data.item.fiyat }} TL
              </template>
              <template #cell(kayitTarih)="data">
                {{ data.item.kayitTarih.slice(0, -8) }}
              </template>
              <template #cell(cikisTarih)="data">
                {{ data.item.cikisTarih }}
              </template>
              <template #cell(checkOut)="data">
                <b-badge
                    pill
                    :variant="data.item.odemeDurum == 1 ? 'bgInfo' : 'bgDanger'"
                >
                  <strong>
                    {{
                      data.item.odemeDurum == 1
                          ? 'Tahsilat Tam / Aktif Konaklama'
                          : 'Tahsilat Bek / Aktif Konaklama'
                    }}
                  </strong>
                </b-badge>
              </template>
            </b-table>
            <hr/>
            <b-row class="alignItemsCenter mt-1">
              <b-col cols="6">
                <p class="text-muted">
                  Toplam {{ todayToplamKayit }} Kayıt Bulundu
                </p>
              </b-col>
              <div class="demo-spacing-0">
                <b-col cols="6">
                  <b-pagination
                      :align="'rigth'"
                      :per-page="perPage"
                      @change="currentPageChange($event)"
                      v-model="currentPage"
                      :total-rows="todayToplamKayit"
                      first-number
                      last-number
                      prev-class="prev-item"
                      next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18"/>
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18"/>
                    </template>
                  </b-pagination>
                </b-col>
              </div>
            </b-row>
          </b-tab>
          <!--  GELECEK KONAKLAMALAR -->
          <b-tab title="Çıkış Yapan Konuklar">
            <b-row>
              <b-col sm="3" md="3" class="my-1">
                <b-input-group>
                  <b-input-group size="sm" prepend="Gösterilecek kayıt sayısı">
                    <b-form-select
                        @change="perPageChangeT($event)"
                        id="per-page-select"
                        v-model="TperPage"
                        :options="pageOptionsT"
                        size="sm"
                    >
                    </b-form-select>
                  </b-input-group>
                </b-input-group>
              </b-col>
              <b-col lg="3" class="my-1 d-flex justify-content-end">
                <b-input-group size="sm" class="searchGroup">
                  <b-input-group-prepend>
                    <b-button
                        variant="outline-primary"
                        size="sm"
                        disabled
                        class="araButton"
                    >
                      Ara
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input
                      id="filter-input"
                      v-model="filterT"
                      type="search"
                  ></b-form-input>
                </b-input-group>
              </b-col>
              <b-table
                  hover
                  striped
                  empty-text=""
                  :items="tSalaryListItems"
                  :fields="fields"
                  :per-page="TperPage"
                  :filter-included-fields="filterOn"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  stacked="md"
                  show-empty
                  small
                  @filtered="onFiltered"
              >
                <template #cell(odaKodu)="data">
                  {{ data.item.odaKodu }}
                </template>
                <template #cell(rezervasyonNo)="data">
                  <a>{{
                      data.item.rezervasyonNo
                    }}</a>
                </template>
                <template #cell(tarife)="data">
                  {{ tarifeGet(data.item.tarife) }}
                </template>
                <template #cell(adi)="data">
                  {{ data.item.adi }} {{ data.item.soyAdi }}
                </template>
                <template #cell(kayitTarih)="data">
                  {{ data.item.kayitTarih.slice(0, -8) }}
                </template>
                <template #cell(cikisTarih)="data">
                  {{ data.item.cikisTarih }}
                </template>
                <template #cell(checkOut)="data">
                  <b-badge
                      pill
                      :variant="data.item.odemeDurum == 1 ? 'bgInfo' : 'bgDanger'"
                  >
                    <strong>
                      {{
                        data.item.odemeDurum == 1
                            ? 'Tahsilat Tam / Çıkış Yapıldı'
                            : 'Tahsilat Bek / Çıkış Yapıldı'
                      }}
                    </strong>
                  </b-badge>
                </template>
              </b-table>
              <hr/>
            </b-row>
            <hr/>
            <b-row>
              <b-col cols="6">
                <p class="text-muted">
                  Toplam {{ TtodayToplamKayit }} Kayıt Bulundu
                </p>
              </b-col>
              <div class="demo-spacing-0">
                <b-col cols="6" class="">
                  <b-pagination
                      :align="'rigth'"
                      :per-page="TperPage"
                      @change="TcurrentPageChange($event)"
                      v-model="TcurrentPage"
                      :total-rows="TtodayToplamKayit"
                      first-number
                      last-number
                      prev-class="prev-item"
                      next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18"/>
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18"/>
                    </template>
                  </b-pagination>
                </b-col>
              </div>
            </b-row>
          </b-tab>
        </b-tabs>
        <b-tabs v-if="filtre" id="printMe">
          <!--  AKTİF KONAKLAMALAR -->
          <b-tab active title="Aktif Konuklar">
            <b-row>
              <b-col sm="3" md="3" class="my-1">
                <b-input-group>
                  <b-input-group
                      size="sm"
                      prepend="Gösterilecek kayıt sayısı"
                      class="unPrint"
                  >
                    <b-form-select
                        @change="perPageChange($event)"
                        id="per-page-select"
                        v-model="perPage"
                        :options="pageOptions"
                        size="sm"
                    >
                    </b-form-select>
                  </b-input-group>
                </b-input-group>
              </b-col>
              <b-col lg="3" class="my-1 d-flex justify-content-end">
                <b-input-group size="sm" class="searchGroup">
                  <b-input-group-prepend>
                    <b-button
                        variant="outline-primary"
                        size="sm"
                        disabled
                        class="araButton"
                    >
                      Ara
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                  ></b-form-input>
                </b-input-group>
              </b-col>
            </b-row>
            <b-table
                hover
                striped
                empty-text=""
                :items="salaryListItemsFiltreActive"
                :fields="fields"
                :per-page="perPage"
                :filter-included-fields="filterOn"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                stacked="md"
                show-empty
                small
                @filtered="onFiltered"
            >
              <template #cell(odaKodu)="data">
                {{ data.item.odaKodu }}
              </template>
              <template #cell(rezervasyonNo)="data">
                <a>{{
                    data.item.rezervasyonNo
                  }}</a>
              </template>
              <template #cell(adi)="data">
                {{ data.item.adi }} {{ data.item.soyAdi }}
              </template>
              <template #cell(tarife)="data">
                {{ tarifeGet(data.item.tarife) }}
              </template>
              <template #cell(fiyat)="data">
                {{ data.item.fiyat }} TL
              </template>
              <template #cell(kayitTarih)="data">
                {{ data.item.kayitTarih.slice(0, -8) }}
              </template>
              <template #cell(cikisTarih)="data">
                {{ data.item.cikisTarih }}
              </template>
              <template #cell(checkOut)="data">
                <b-badge
                    pill
                    :variant="data.item.odemeDurum == 1 ? 'bgInfo' : 'bgDanger'"
                >
                  <strong>
                    {{
                      data.item.odemeDurum == 1
                          ? 'Tahsilat Tam / Aktif Konaklama'
                          : 'Tahsilat Bek / Aktif Konaklama'
                    }}
                  </strong>
                </b-badge>
              </template>
            </b-table>
            <hr/>
            <b-row class="alignItemsCenter mt-1">
              <b-col cols="6">
                <p class="text-muted">
                  Toplam {{ todayToplamKayit }} Kayıt Bulundu
                </p>
              </b-col>
              <div class="demo-spacing-0">
                <b-col cols="6">
                  <b-pagination
                      :align="'rigth'"
                      :per-page="perPage"
                      @change="currentPageChange($event)"
                      v-model="currentPage"
                      :total-rows="todayToplamKayit"
                      first-number
                      last-number
                      prev-class="prev-item"
                      next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18"/>
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18"/>
                    </template>
                  </b-pagination>
                </b-col>
              </div>
            </b-row>
          </b-tab>
          <!--  GELECEK KONAKLAMALAR -->
          <b-tab title="Çıkış Yapan Konuklar">
            <b-row>
              <b-col sm="3" md="3" class="my-1">
                <b-input-group>
                  <b-input-group size="sm" prepend="Gösterilecek kayıt sayısı">
                    <b-form-select
                        @change="perPageChangeT($event)"
                        id="per-page-select"
                        v-model="TperPage"
                        :options="pageOptionsT"
                        size="sm"
                    >
                    </b-form-select>
                  </b-input-group>
                </b-input-group>
              </b-col>
              <b-col lg="3" class="my-1 d-flex justify-content-end">
                <b-input-group size="sm" class="searchGroup">
                  <b-input-group-prepend>
                    <b-button
                        variant="outline-primary"
                        size="sm"
                        disabled
                        class="araButton"
                    >
                      Ara
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input
                      id="filter-input"
                      v-model="filterT"
                      type="search"
                  ></b-form-input>
                </b-input-group>
              </b-col>
              <b-table
                  hover
                  striped
                  empty-text=""
                  :items="salaryListItemsFiltrePasif"
                  :fields="fields"
                  :per-page="TperPage"
                  :filter-included-fields="filterOn"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  stacked="md"
                  show-empty
                  small
                  @filtered="onFiltered"
              >
                <template #cell(odaKodu)="data">
                  {{ data.item.odaKodu }}
                </template>
                <template #cell(rezervasyonNo)="data">
                  <a>{{
                      data.item.rezervasyonNo
                    }}</a>
                </template>
                <template #cell(tarife)="data">
                  {{ tarifeGet(data.item.tarife) }}
                </template>
                <template #cell(adi)="data">
                  {{ data.item.adi }} {{ data.item.soyAdi }}
                </template>
                <template #cell(kayitTarih)="data">
                  {{ data.item.kayitTarih.slice(0, -8) }}
                </template>
                <template #cell(cikisTarih)="data">
                  {{ data.item.cikisTarih }}
                </template>
                <template #cell(checkOut)="data">
                  <b-badge
                      pill
                      :variant="data.item.odemeDurum == 1 ? 'bgInfo' : 'bgDanger'"
                  >
                    <strong>
                      {{
                        data.item.odemeDurum == 1
                            ? 'Tahsilat Tam / Çıkış Yapıldı'
                            : 'Tahsilat Bek / Çıkış Yapıldı'
                      }}
                    </strong>
                  </b-badge>
                </template>
              </b-table>
              <hr/>
            </b-row>
            <hr/>
            <b-row>
              <b-col cols="6">
                <p class="text-muted">
                  Toplam {{ TtodayToplamKayit }} Kayıt Bulundu
                </p>
              </b-col>
              <div class="demo-spacing-0">
                <b-col cols="6">
                  <b-pagination
                      :align="'rigth'"
                      :per-page="TperPage"
                      @change="TcurrentPageChange($event)"
                      v-model="TcurrentPage"
                      :total-rows="TtodayToplamKayit"
                      first-number
                      last-number
                      prev-class="prev-item"
                      next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18"/>
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18"/>
                    </template>
                  </b-pagination>
                </b-col>
              </div>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-card-actions>
    </div>
  </b-container>
</template>

<script>
import {
  BRow,
  BCol,
  BContainer,
  BTable,
  BBadge,
  BForm,
  BButton,
  BCard,
  BTabs,
  BTab,
  BBreadcrumb,
  BButtonGroup,
  BFormInput,
  BInputGroup,
  BSpinner,
  BAvatar,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormCheckboxGroup,
  BFormCheckbox,
  BModal,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'

const Turkish = require('flatpickr/dist/l10n/tr.js').default.tr
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

flatpickr.localize(Turkish)
flatpickr(flatPickr)
export default {
  components: {
    BCardActions,
    BInputGroupPrepend,
    BRow,
    BCol,
    BContainer,
    BTable,
    BTabs,
    BTab,
    BBadge,
    BForm,
    BButton,
    BCard,
    BBreadcrumb,
    BButtonGroup,
    BFormInput,
    BInputGroup,
    BSpinner,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroupAppend,
    BFormCheckboxGroup,
    BFormCheckbox,
    BModal,
    flatPickr,
  },
  data() {
    return {
      spinner: true,
      todayToplamKayit: '',
      TtodayToplamKayit: '',
      filtre: false,
      islem: false,
      baslangic: '',
      bitis: '',
      fields: [
        {
          key: 'odaKodu',
          label: 'ODA',
          sortable: true,
          sortDirection: 'desc',
          sortByFormatted: true,
          filterByFormatted: true,
        },
        {
          key: 'rezervasyonNo',
          label: 'RNO',
          class: 'text-center',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
        },
        {
          key: 'adi',
          label: 'KONUK İSMİ',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
        },
        {
          key: 'tarife',
          label: 'TARİFE',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
        },
        {
          key: 'fiyat',
          label: 'GECELİK FİYAT',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
        },
        {
          key: 'girisTarih',
          label: 'GİRİŞ TARİHİ',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
        },
        {
          key: 'cikisTarih',
          label: 'ÇIKIŞ TARİHİ',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
        },
        {
          key: 'checkOut',
          label: 'DURUM',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thClass: ['text-center'],
          tdClass: ['text-center'],
        },
        {
          key: 'actions',
          label: ''
        },
      ],
      totalRows: 1,
      TtotalRows: 1,
      currentPage: 1,
      TcurrentPage: 1,
      perPage: 20,
      TperPage: 20,
      pageOptions: [5, 10, 15, 20, 25, 50, 100],
      pageOptionsT: [5, 10, 15, 20, 25, 50, 100],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      filterT: null,
      filterOnT: [],
    }
  },
  computed: {
    salaryListItems() {
      if (this.$store.getters.konaklamaListActive) {
        return this.$store.getters.konaklamaListActive
      }
      return []
    },
    tSalaryListItems() {
      if (this.$store.getters.konaklamaListPasif) {
        return Object.values(this.$store.getters.konaklamaListPasif)
      }
    },
    salaryListItemsFiltreActive() {
      if (this.$store.getters.konaklamaListActiveFiltre) {
        return Object.values(this.$store.getters.konaklamaListActiveFiltre)
      }
    },
    salaryListItemsFiltrePasif() {
      if (this.$store.getters.konaklamaListPasifFiltre) {
        return Object.values(this.$store.getters.konaklamaListPasifFiltre)
      }
    },
    currentDate() {
      return localStorage.getItem('raporCurrentDate')
    },
  },
  watch: {
    baslangic(newVal) {
      if (newVal) {
        this.currentPage = 1
        this.filtre = true
        this.islem = true
        const veri = newVal * this.perPage - this.perPage
        this.$refs['refreshCard'].showLoading = true
        let today = new Date()
        const yil = today.getFullYear()
        var gun = String(today.getDate())
            .padStart(2, '0')
        var ay = String(today.getMonth() + 1)
            .padStart(2, '0')
        const mevcutGun = yil + '-' + ay + '-' + gun
        this.$store
            .dispatch('konaklamaListActiveFiltre', {
              start: newVal,
              end: this.bitis || mevcutGun,
              uzunluk: this.perPage,
              baslangic: veri,
              searchKey: this.filter || this.filterT,
              filtre: true,
            })
            .then((res) => {
              this.islem = false
              // this.$refs['refreshCard'].showLoading = false
              this.todayToplamKayit = res.aktifSayac
              this.totalRows = res.aktifKonuklar.length
            })
        const veriT = newVal * this.TperPage - this.TperPage
        this.$store
            .dispatch('konaklamaListPasifFiltre', {
              start: newVal,
              end: this.bitis || mevcutGun,
              uzunluk: this.TperPage,
              baslangic: veriT,
              searchKey: this.filter || this.filterT,
              filtre: true,
            })
            .then((res) => {
              this.islem = false
              this.TtodayToplamKayit = res.pasifSayac
              this.totalRows = res.pasifKonuklar.length
            })
        if (!this.bitis) {
          this.bitis = mevcutGun
        }
      }
    },
    bitis(newVal) {
      if (newVal) {
        this.TcurrentPage = 1
        this.filtre = true
        this.islem = true
        const veri = newVal * this.perPage - this.perPage
        this.$refs['refreshCard'].showLoading = true
        let today = new Date()
        const yil = today.getFullYear()
        var gun = String(today.getDate())
            .padStart(2, '0')
        var ay = String(today.getMonth() + 1)
            .padStart(2, '0')
        const mevcutGun = yil + '-' + ay + '-' + gun
        this.$store
            .dispatch('konaklamaListActiveFiltre', {
              start: this.baslangic || mevcutGun,
              end: newVal,
              uzunluk: this.perPage,
              baslangic: veri,
              searchKey: this.filter || this.filterT,
              filtre: true,
            })
            .then((res) => {
              this.islem = false
              this.$refs['refreshCard'].showLoading = false
              this.todayToplamKayit = res.aktifSayac
              this.totalRows = res.aktifKonuklar.length
            })
        const veriT = newVal * this.TperPage - this.TperPage
        this.$store
            .dispatch('konaklamaListPasifFiltre', {
              start: this.baslangic || mevcutGun,
              end: newVal,
              uzunluk: this.TperPage,
              baslangic: veriT,
              searchKey: this.filter || this.filterT,
              filtre: true,
            })
            .then((res) => {
              this.islem = false
              this.TtodayToplamKayit = res.pasifSayac
              this.totalRows = res.pasifKonuklar.length
            })
        if (!this.baslangic) {
          this.baslangic = mevcutGun
        }
      }
    },
    filter(newVal) {
      if (this.baslangic && this.bitis) {
        this.islem = true
        this.$refs['refreshCard'].showLoading = true
        this.$store
            .dispatch('konaklamaListActiveFiltre', {
              uzunluk: this.perPage,
              baslangic: 0,
              searchKey: newVal,
              start: this.baslangic,
              end: this.bitis,
              filtre: true,
            })
            .then((res) => {
              this.islem = false
              this.$refs['refreshCard'].showLoading = false
              this.todayToplamKayit = res.aktifSayac
              this.totalRows = res.aktifKonuklar.length
            })
      } else {
        this.islem = true
        this.$refs['refreshCard'].showLoading = true
        this.$store
            .dispatch('konaklamaListActive', {
              uzunluk: this.perPage,
              baslangic: 0,
              searchKey: newVal,
              filtre: false,
            })
            .then((res) => {
              this.islem = false
              this.$refs['refreshCard'].showLoading = false
              this.todayToplamKayit = res.aktifSayac
              this.totalRows = res.aktifKonuklar.length
            })
      }
    },
    filterT(newVal) {
      if (this.baslangic && this.bitis) {
        this.islem = true
        this.$refs['refreshCard'].showLoading = true
        this.$store
            .dispatch('konaklamaListPasifFiltre', {
              uzunluk: this.TperPage,
              baslangic: 0,
              searchKey: newVal,
              start: this.baslangic,
              end: this.bitis,
              filtre: true,
            })
            .then((res) => {
              this.islem = false
              this.$refs['refreshCard'].showLoading = false
              this.TtodayToplamKayit = res.pasifSayac
              this.TtotalRows = res.pasifKonuklar.length
            })
      } else {
        this.islem = true
        this.$refs['refreshCard'].showLoading = true
        this.$store
            .dispatch('konaklamaListPasif', {
              uzunluk: this.TperPage,
              baslangic: 0,
              searchKey: newVal,
              filtre: false,
            })
            .then((res) => {
              this.islem = false
              this.$refs['refreshCard'].showLoading = false
              this.TtodayToplamKayit = res.pasifSayac
              this.TtotalRows = res.pasifKonuklar.length
            })
      }
    },
  },
  methods: {
    currentPageChange(newVal) {
      if (this.baslangic && this.bitis) {
        const veri = newVal * this.perPage - this.perPage
        let today = new Date()
        const yil = today.getFullYear()
        var gun = String(today.getDate())
            .padStart(2, '0')
        var ay = String(today.getMonth() + 1)
            .padStart(2, '0')
        const mevcutGun = yil + '-' + ay + '-' + gun
        this.islem = true
        this.$refs['refreshCard'].showLoading = true
        this.$store
            .dispatch('konaklamaListActiveFiltre', {
              uzunluk: this.perPage,
              baslangic: veri,
              searchKey: this.filter,
              start: this.baslangic || mevcutGun,
              end: this.bitis || mevcutGun,
              filtre: true,
            })
            .then((res) => {
              this.islem = false
              this.$refs['refreshCard'].showLoading = false
              this.todayToplamKayit = res.aktifSayac
              this.totalRows = res.aktifKonuklar.length
            })
      } else {
        const veri = newVal * this.perPage - this.perPage
        this.$refs['refreshCard'].showLoading = true
        this.$store
            .dispatch('konaklamaListActive', {
              uzunluk: this.perPage,
              baslangic: veri,
              searchKey: this.filter,
              filtre: false,
            })
            .then((res) => {
              this.islem = false
              this.$refs['refreshCard'].showLoading = false
              this.todayToplamKayit = res.aktifSayac
              this.totalRows = res.aktifKonuklar.length
            })
      }
    },
    TcurrentPageChange(newVal) {
      if (this.baslangic && this.bitis) {
        const veri = newVal * this.TperPage - this.TperPage
        let today = new Date()
        const yil = today.getFullYear()
        var gun = String(today.getDate())
            .padStart(2, '0')
        var ay = String(today.getMonth() + 1)
            .padStart(2, '0')
        const mevcutGun = yil + '-' + ay + '-' + gun
        this.islem = true
        this.$refs['refreshCard'].showLoading = true
        this.$store
            .dispatch('konaklamaListPasifFiltre', {
              uzunluk: this.perPage,
              baslangic: veri,
              searchKey: this.filterT,
              start: this.baslangic || mevcutGun,
              end: this.bitis || mevcutGun,
              filtre: true,
            })
            .then((res) => {
              this.islem = false
              this.$refs['refreshCard'].showLoading = false
              this.TtodayToplamKayit = res.pasifSayac
              this.TtotalRows = res.pasifKonuklar.length
            })
      } else {
        const veri = newVal * this.TperPage - this.TperPage
        this.$refs['refreshCard'].showLoading = true
        this.$store
            .dispatch('konaklamaListPasif', {
              uzunluk: this.TperPage,
              baslangic: veri,
              searchKey: this.filterT,
              filtre: false,
            })
            .then((res) => {
              this.islem = false
              this.$refs['refreshCard'].showLoading = false
              this.TtodayToplamKayit = res.pasifSayac
              this.TtotalRows = res.pasifKonuklar.length
            })
      }
    },
    perPageChange(newVal) {
      if (this.baslangic && this.bitis) {
        const veri = this.currentPage * newVal - newVal
        this.$refs['refreshCard'].showLoading = true
        this.$store
            .dispatch('konaklamaListActiveFiltre', {
              uzunluk: newVal,
              baslangic: veri,
              searchKey: this.filter,
              start: this.baslangic,
              end: this.bitis,
              filtre: true,
            })
            .then((res) => {
              this.todayToplamKayit = res.aktifSayac
              this.totalRows = res.aktifKonuklar.length
              this.islem = false
              this.$refs['refreshCard'].showLoading = false
            })
      } else {
        const veri = this.currentPage * newVal - newVal
        this.$refs['refreshCard'].showLoading = true
        this.$store
            .dispatch('konaklamaListActive', {
              uzunluk: newVal,
              baslangic: veri,
              searchKey: this.filter,
            })
            .then((res) => {
              this.islem = false
              this.$refs['refreshCard'].showLoading = false
              this.todayToplamKayit = res.aktifSayac
              this.totalRows = res.aktifKonuklar.length
            })
      }
    },
    perPageChangeT(newVal) {
      if (this.baslangic && this.bitis) {
        const veri = this.currentPage * newVal - newVal
        this.$refs['refreshCard'].showLoading = true
        this.$store
            .dispatch('konaklamaListPasifFiltre', {
              uzunluk: newVal,
              baslangic: veri,
              searchKey: this.filterT,
              start: this.baslangic,
              end: this.bitis,
              filtre: true,
            })
            .then((res) => {
              this.todayToplamKayit = res.pasifSayac
              this.totalRows = res.pasifKonuklar.length
              this.islem = false
              this.$refs['refreshCard'].showLoading = false
            })
      } else {
        const veri = this.TcurrentPage * newVal - newVal
        this.$refs['refreshCard'].showLoading = true
        this.$store
            .dispatch('konaklamaListPasif', {
              uzunluk: newVal,
              baslangic: veri,
              searchKey: this.filterT,
            })
            .then((res) => {
              this.TcurrentPage = 1
              this.islem = false
              this.$refs['refreshCard'].showLoading = false
              this.TtodayToplamKayit = res.pasifSayac
              this.TtotalRows = res.pasifKonuklar.length
            })
      }
    },
    refreshStop() {
      this.filtre = false
      this.perPage = 20
      this.TperPage = 20
      this.baslangic = ''
      this.bitis = ''
      this.filter = ''
      this.filterT = ''
      this.islem = true
      this.$store
          .dispatch('konaklamaListActive', {
            uzunluk: this.perPage,
            baslangic: 0,
          })
          .then((res, position = 'bottom-right') => {
            this.islem = false
            this.todayToplamKayit = res.aktifSayac
            this.totalRows = res.aktifKonuklar.length
            const mesaj = this.$store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Başarılı',
                      text: mesaj,
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  },
                  { position }
              )
            }
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
      this.$store
          .dispatch('konaklamaListPasif', {
            uzunluk: this.perPage,
            baslangic: 0,
          })
          .then((res, position = 'bottom-right') => {
            this.islem = false
            this.TtodayToplamKayit = res.pasifSayac
            this.TtotalRows = res.gelecekKonuklar.length
            const mesaj = this.$store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Başarılı',
                      text: mesaj,
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  },
                  { position }
              )
            }
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    tarifeGet(tarifeID) {
      const veri = Object.values(this.$store.getters.tarife)
          .find(obj => {
            return obj.tarifeID === tarifeID
          })
      if (veri) {
        return veri.tarifeAdi
      }
    },
  },
  created() {
    setTimeout(() => {
      this.spinner = false
    }, 200)
    this.islem = true
    this.$store
        .dispatch('konaklamaListActive', {
          uzunluk: this.perPage,
          baslangic: 0,
        })
        .then((res, position = 'bottom-right') => {
          this.islem = false
          this.todayToplamKayit = res.aktifSayac
          this.totalRows = res.aktifKonuklar.length
          const mesaj = this.$store.getters.notificationSettings(res)
          if (mesaj) {
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          }
        })
        .catch(err => {
          const mesaj = this.$store.getters.notificationSettings(err)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          }
        })
    this.$store
        .dispatch('konaklamaListPasif', {
          uzunluk: this.perPage,
          baslangic: 0,
        })
        .then((res, position = 'bottom-right') => {
          this.islem = false
          this.TtodayToplamKayit = res.pasifSayac
          this.TtotalRows = res.gelecekKonuklar.length
          const mesaj = this.$store.getters.notificationSettings(res)
          if (mesaj) {
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          }
        })
        .catch(err => {
          const mesaj = this.$store.getters.notificationSettings(err)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          }
        })
  },
  beforeDestroy() {
    this.$store.commit('clearPosFatura')
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style>
.konaklama-listesi .card .card-header {
  display: none !important;
}
</style>
<style scoped>
.spinners {
  position: absolute !important;
  margin-left: 47% !important;
  margin-top: 15% !important;
  width: 3rem !important;
  height: 3rem !important;
  z-index: 900 !important;
}

.col-form-label {
  transform: translate(10px, -2px);
}

.custom-select-sm {
  max-width: 80px !important;
}

.perPageText {
  transform: translate(-69px, 0px);
  font-size: 15px;
}

.cardActions .card-body {
  min-height: 550px;
}

.mr-12 {
  margin-right: 12px !important;
  background-color: #2e83c9 !important;
}

.bg-primaryy {
  background-color: rgb(49 82 111) !important;
}

.badge-primary {
  background-color: rgba(115, 103, 240, 0.12);
  color: #7367f0 !important;
}

.myButton {
  color: white !important;
}

.myButton:hover {
  color: white !important;
}

a {
  color: #408dcc !important;
}

a:hover {
  color: #6d62e4 !important;
}

.card-header {
  background-color: #2e83c9;
  height: 30px;
  position: relative;
  display: none !important;
}

.card-header h4 {
  position: absolute;
  color: white;
  margin-top: 10px;
}

.row {
  justify-content: space-between;
}

.col-form-label {
  transform: translate(29px, -2px);
}

.sayfadaText {
  transform: translateX(-30px);
}

fieldset legend {
  transform: translate(29px, -2px) !important;
}

.araButton {
  border-color: #d8d6de !important;
}

.input-group:not(.bootstrap-touchspin):focus-within {
  box-shadow: none !important;
}

.input-group:not(.bootstrap-touchspin):focus-within .form-control,
.input-group:not(.bootstrap-touchspin):focus-within .input-group-text {
  border-color: #d8d6de;
}

.disabledColor {
  border-color: #cbc1eb !important;
}

hr {
  border-top: 1px solid #e5e2ef;
}

.alignItemsCenter {
  align-items: center !important;
}

.searchGroup {
  width: 60% !important;
}

.myTitle {
  white-space: nowrap !important;
  font-size: 1.2vw !important;
}

</style>
